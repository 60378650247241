<template>
  <div class="d-flex align-center justify-space-between mb-2">
    <div
      class="d-flex align-center"
    >
      <!-- ПОИСК -->
      <v-text-field
        :value="form.id"
        :v-model="form.id"
        label="ID"
        dense
        solo
        single-line
        hide-details
        clearable
        @input="setId"
      />
      <v-spacer />
      <v-text-field
        :value="form.teamName"
        :v-model="form.teamName"
        label="Email"
        dense
        solo
        single-line
        hide-details
        clearable
        @input="setTeamName"
      />
      <v-spacer />
      <v-select
        :items="statuses"
        :v-model="form.statuses"
        label="Status"
        @change="setStatus"
      />
      <v-spacer />
      <v-select
        :items="providers"
        label="Provider"
        :v-model="form.provider"
        @change="setProvider"
      />
      <v-spacer />
      <v-text-field
        :value="form.sum"
        :v-model="form.sum"
        label="Сумма"
        dense
        solo
        single-line
        hide-details
        clearable
        @input="setSum"
      />
      <v-select
        :items="currencies"
        label="Currency"
        :v-model="form.currency"
        @change="setCurrency"
      />
      <v-spacer />
      <v-select
        :items="coins"
        label="Coin"
        :v-model="form.coin"
        @change="setCoin"
      />
      <v-spacer />
      <v-select
        :items="plans"
        label="Plan"
        :v-model="form.plan"
        @change="setPlan"
      />
      <v-spacer />

      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.createdAt"
            clearable
            label="от даты"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="form.createdAt"
          @input="menu = false; search();"
        />
      </v-menu>
      <v-spacer />
      <v-text-field
        :value="form.orderId"
        :v-model="form.orderId"
        label="Заказ"
        dense
        solo
        single-line
        hide-details
        clearable
        @input="setOrder"
      />
      <v-spacer />
      <v-text-field
        :value="form.query"
        :v-model="form.query"
        label="Что ищем?"
        dense
        solo
        single-line
        hide-details
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        @input="setQuery"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InvoicesTopbar',
  data() {
    return {
      loadingRefreshButton: false,
      menu: false,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'invoices/loading',
      profile: 'main/profile',
      statuses: 'invoices/statuses',
      providers: 'invoices/providers',
      coins: 'invoices/coins',
      plans: 'invoices/plans',
      currencies: 'invoices/currencies',
      form: 'invoices/form',
      pagination: 'invoices/pagination',
    }),
  },

  methods: {
    setPlan(plan) {
      this.form.plan = plan;
      this.search();
    },
    setCurrency(currency) {
      this.form.currency = currency;
      this.search();
    },
    setCoin(coin) {
      this.form.coin = coin;
      this.search();
    },
    setStatus(status) {
      this.form.status = status;
      this.search();
    },
    setProvider(provider) {
      this.form.provider = provider;
      this.search();
    },
    setTeamName(teamName) {
      this.form.teamName = teamName;
      this.search();
    },
    setQuery(query) {
      this.form.query = query;
      this.search();
    },
    setId(id) {
      this.form.id = id;
      this.search();
    },
    setSum(sum) {
      this.form.sum = sum;
      this.search();
    },
    setOrder(orderId) {
      this.form.orderId = orderId;
      this.search();
    },
    setTeam(teamId) {
      this.form.teamId = teamId;
      this.search();
    },
    search() {
      setTimeout(async () => {
        await this.$store.dispatch('invoices/loadInvoices',
          {
            ...this.form,
            itemsPerPage: this.pagination.perPage,
          });
      }, 500);
    },
  },
};
</script>
