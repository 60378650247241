<template>
  <div>
    <topbar />
    <main-table />
  </div>
</template>

<script>
import MainTable from '../components/invoices/InvoicesMainTable.vue';
import Topbar from '../components/invoices/InvoicesTopbar.vue';

export default {
  name: 'Invoices',
  components: { MainTable, Topbar },
};
</script>

<style scoped>

</style>
