export default {
  methods: {
    sortBy() {
      const index = localStorage.getItem('anty-sorting-index');
      if (index && index !== 'undefined') {
        return localStorage.getItem('anty-sorting-index');
      }
      return [];
    },

    sortDesc() {
      const isDesc = localStorage.getItem('anty-sorting-is-desc');
      if (isDesc && isDesc !== 'undefined') {
        return localStorage.getItem('anty-sorting-is-desc') === 'true';
      }
      return [];
    },

    customSort(items, index, isDesc) {
      let filterSortBy = index[0];
      let filterOrder = isDesc[0];
      localStorage.setItem('anty-sorting-index', filterSortBy);
      localStorage.setItem('anty-sorting-is-desc', filterOrder);
      switch (filterSortBy) {
        case 'created':
          filterSortBy = 'created_at';
          break;
        case 'team':
          filterSortBy = 'teamName';
          break;
        case undefined:
          filterSortBy = '';
          break;
        default:
          filterSortBy = filterSortBy !== undefined ? filterSortBy : '';
          break;
      }

      switch (filterOrder) {
        case true:
          filterOrder = 'ASC';
          break;
        case false:
          filterOrder = 'DESC';
          break;
        default:
          filterOrder = '';
          break;
      }
      if (!this.$_.isEmpty(items)) {
        if (this.isInvoices) {
          this.$store.dispatch('invoices/setFilter', { sortBy: filterSortBy, order: filterOrder });
        } else {
          this.$store.dispatch('financeLogs/setFilter', { sortBy: filterSortBy, order: filterOrder });
        }
      }

      return [...items];
    },
  },
};
