<template>
  <v-card>
    <!-- ТАБЛИЦА -->
    <v-data-table
      v-model="invoices.selected"
      :items="invoices.all"
      item-key="id"
      :headers="cols"
      fixed-header
      :height="innerHeight - 16 - 46 - 69"
      :items-per-page="footerOptions.itemsPerPage"
      hide-default-footer
      show-select
      :loading="loading.mainTable"
      :custom-sort="customSort"
      :sort-by="sortBy()"
      :sort-desc="sortDesc()"
    >
      <!-- ФУТЕР -->
      <template #footer>
        <v-data-footer
          :options="footerOptions"
          :pagination="footerPagination"
          :items-per-page-options="[10, 30, 50]"
          @update:options="updateFooterOptions"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import moment from 'moment';
// import CellActions from './InvoicesMainTableCellActions.vue';
import customSort from '../../mixins/customSort';

export default {
  name: 'InvoicesMainTable',

  components: {
    // CellActions,
  },

  mixins: [customSort],

  data() {
    return {
      moment,
      isInvoices: true,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'invoices/loading',
      innerHeight: 'main/innerHeight',
      pagination: 'invoices/pagination',
      profile: 'main/profile',
      form: 'invoices/form',
    }),
    ...mapFields('invoices', ['invoices']),

    cols() {
      const cols = [];

      cols.push({ text: 'ID', value: 'id' });
      cols.push({ text: 'Team', value: 'name' });
      cols.push({ text: 'Status', value: 'status' });
      cols.push({ text: 'Provider', value: 'provider' });
      cols.push({ text: 'Sum', value: 'sum' });
      cols.push({ text: 'Currency', value: 'currency' });
      cols.push({ text: 'Exchange', value: 'exchange' });
      cols.push({ text: 'Fee', value: 'fee' });
      cols.push({ text: 'Coin', value: 'coin' });
      cols.push({ text: 'Plan', value: 'plan' });
      cols.push({ text: 'Users Limit', value: 'usersLimit' });
      cols.push({ text: 'BP Limit', value: 'browserProfilesLimit' });
      cols.push({ text: 'Days', value: 'days' });
      cols.push({ text: 'Created', value: 'created_at' });
      // cols.push({
      //   text: 'Actions', value: 'actions', width: 100, align: 'end', sortable: false,
      // });

      return cols;
    },

    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from - 1;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },

  methods: {

    updateFooterOptions(options) {
      this.$store.dispatch('invoices/loadInvoices', {
        page: options.page,
        itemsPerPage: options.itemsPerPage,
        ...this.form,
      });
    },

  },
};
</script>
